import axios from "axios";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { firebaseAuth } from "../../firebase";
import * as t from "../constants/authConstants";
import { mapAuthCodeToMessage } from "../../utils/firebaseAuthErrorHandling";
import { toast } from "react-toastify";

// Sign in user with email and password to firebase and then retrieve user from backend and pass to redux state.
export const signInUserEmailAndPassword =
  (email, password) => async (dispatch) => {
    dispatch({ type: t.ACTION_LOADING_AUTH });
    // Sign in firebase user
    signInWithEmailAndPassword(firebaseAuth, email, password)
      .then(async (userCredential) => {
        var userToken = userCredential.user.accessToken;
        // Retrieve user from backend
        try {
          const { data } = await axios.get(
            `${process.env.REACT_APP_BACKEND}/admin/`,
            {
              headers: {
                Authorization: "Bearer " + userToken,
              },
            }
          );
          // Dispatch user to redux state
          dispatch({
            type: t.SIGN_IN_USER,
            payload: data,
          });
          dispatch({ type: t.ACTION_COMPLETE_AUTH });
        } catch (error) {
          console.log(error);
          dispatch({ type: t.ACTION_FAILED_AUTH });
        }
        dispatch({ type: t.ACTION_RESET_AUTH });
      })
      .catch((error) => {
        const message = mapAuthCodeToMessage(error.code);
        console.log(message === "" ? error.code : message);
        toast.error(message === "" ? error.code : message);
        dispatch({ type: t.ACTION_FAILED_AUTH });
      });
  };

// Used where firebase authentication already present to retrieve user from backend and pass to redux state
export const signInUserToken = (token) => async (dispatch) => {
  // Retrieve user from backend
  try {
    const { data } = await axios.get(`${process.env.REACT_APP_BACKEND}/auth/`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    // Dispatch user to redux state
    dispatch({
      type: t.SIGN_IN_USER,
      payload: data,
    });
  } catch (error) {
    console.log(error);
    // Clears firebase authentication if error retrieving user from backend
    dispatch(signOutUser());
  }
};

// Sign out user from firebase and clear user state from redux
export const signOutUser = () => (dispatch) => {
  // Sign out user from firebase
  signOut(firebaseAuth)
    .then(() => {
      // Clear redux state
      dispatch({
        type: t.SIGN_OUT_USER,
      });
    })
    .catch((error) => {
      console.log(error);
    });
};

// Update user retrieves firebase authentication token and updates user on backend, then passes new state to redux
export const updateUser = (editUser) => (dispatch) => {
  dispatch({ type: t.ACTION_LOADING_AUTH });
  // Retrieve firebase user token
  firebaseAuth.currentUser.getIdToken(true).then(async (idToken) => {
    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_BACKEND}/auth`,
        {
          editUser,
        },
        {
          headers: {
            Authorization: "Bearer " + idToken,
          },
        }
      );
      // Dispatch updated user to redux state
      dispatch({
        type: t.UPDATE_USER,
        payload: data,
      });
      toast.success("Profile Updated");
      dispatch({ type: t.ACTION_COMPLETE_AUTH });
    } catch (error) {
      toast.error("Error updating profile");
      dispatch({ type: t.ACTION_FAILED_AUTH });
    }
    dispatch({ type: t.ACTION_RESET_AUTH });
  });
};

// Update user bank details -- retrieves firebase authentication token and updates user on backend, then passes new state to redux
export const updateBankDetails = (bankDetails) => (dispatch) => {
  dispatch({ type: t.ACTION_LOADING_AUTH });
  // Retrieve firebase user token
  firebaseAuth.currentUser.getIdToken(true).then(async (idToken) => {
    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_BACKEND}/auth/bank`,
        {
          bankDetails,
        },
        {
          headers: {
            Authorization: "Bearer " + idToken,
          },
        }
      );
      // Dispatch updated user to redux state
      dispatch({
        type: t.UPDATE_USER,
        payload: data,
      });
      toast.success("Bank details updated");
      dispatch({ type: t.ACTION_COMPLETE_AUTH });
    } catch (error) {
      console.log("Error updating bank details: ", error);
      dispatch({ type: t.ACTION_FAILED_AUTH });
    }
    dispatch({ type: t.ACTION_RESET_AUTH });
  });
};
// Used to update redux state when firebase authentication is not already present on application load
export const markNotAuthenticated = () => (dispatch) => {
  dispatch({
    type: t.NOT_AUTHENTICATED,
  });
};
