import React from "react";
import NavLink from "./components/NavLink";

function Sidebar() {
  return (
    <div className='flex flex-col w-2/12 bg-slate-200 p-4'>
      <div className='flex flex-col w-full mt-10'>
        <NavLink to='/restaurants' label='Restaurants' />
      </div>
    </div>
  );
}

export default Sidebar;
