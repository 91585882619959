import React from "react";
import Footer from "../footer";
import Header from "../header";
import Sidebar from "../sidebar";

function Layout({ children }) {
  return (
    <div className='flex min-h-screen'>
      <Sidebar />
      <div className='flex flex-col grow'>
        <Header />
        <div className='flex flex-col grow w-full p-4 bg-slate-50'>
          {children}
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Layout;
